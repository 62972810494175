<template>
    <div class="cycle-profit">
        <el-card>
            <div slot="header" class="clearfix">
                <span>周期盈亏</span>
                <date-range style="float: right" v-on:change="getData"></date-range>
            </div>
            <el-row :gutter="20" class="cycle-profit-charts">
                <el-col :span="8">
                    <h-chart ref="highChart" id="highChart" :options="highOptions"></h-chart>
                </el-col>
                <el-col :span="8">
                    <h-chart ref="daysChart" id="daysChart" :options="daysOptions"></h-chart>
                </el-col>
                <el-col :span="8">
                    <h-chart ref="shortlineChart" id="shortlineChart" :options="shortlineOptions"></h-chart>
                </el-col>
                <el-col :span="12">
                    <h-chart ref="midlineChart" id="midlineChart" :options="midlineOptions"></h-chart>
                </el-col>
                <el-col :span="12">
                    <h-chart ref="klineChart" id="klineChart" :options="klineOptions"></h-chart>
                </el-col>
            </el-row>
            <div class="blackboard">
                <p class="star-level"><span>指标星级：</span>
                    <el-rate v-model="starLevel" disabled></el-rate>
                </p>
                <p>指标释义：高频：持仓时间 ≦ 5min；日内：5min &lt; 持仓时间 ≦ 1day；短线：1day &lt; 持仓时间 ≦ 5day；中线：5day &lt; 持仓时间 ≦
                    15day；长线：持仓时间 >
                    15day。</p>
                <p>
                    如何使用：该指标为“七尾狐”独创，可全面分析账户的交易频率和持仓时间长短。从持仓周期的饼形图中，可分析自己账户不同周期单子的成交额占比，了解自己的交易风格。同时结合不同周期下的盈亏情况和胜率情况，了解自己所擅长的交易频率和周期，建议多做自己擅长的周期。该指标非常重要，推荐每日关注。</p>
            </div>
        </el-card>
    </div>
</template>

<script>
  import HChart from '@/components/HChart.vue'
  import DateRange from '@/components/DateRange.vue'
  import {Loading} from "element-ui";
  import {apiAnalysisData} from "../../api/analysis";

  export default {
    name: "CycleProfit",
    data () {
      return {
        starLevel: 5,
        highOptions: {
          credits: {
            enabled: false
          },
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            }
          },
          title: {
            text: '高频'
          },
          colors: ['#EB3E41', '#229AFC'],
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,
              dataLabels: {
                enabled: true,
                format: '{point.name}'
              }
            }
          },
          series: []
        },
        daysOptions: {
          credits: {
            enabled: false
          },
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            }
          },
          title: {
            text: '日内'
          },
          colors: ['#EB3E41', '#229AFC'],
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,
              dataLabels: {
                enabled: true,
                format: '{point.name}'
              }
            }
          },
          series: []
        },
        shortlineOptions: {
          credits: {
            enabled: false
          },
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            }
          },
          title: {
            text: '短线'
          },
          colors: ['#EB3E41', '#229AFC'],
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,
              dataLabels: {
                enabled: true,
                format: '{point.name}'
              }
            }
          },
          series: []
        },
        midlineOptions: {
          credits: {
            enabled: false
          },
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            }
          },
          title: {
            text: '中线'
          },
          colors: ['#EB3E41', '#229AFC'],
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,
              dataLabels: {
                enabled: true,
                format: '{point.name}'
              }
            }
          },
          series: []
        },
        klineOptions: {
          credits: {
            enabled: false
          },
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            }
          },
          title: {
            text: '长线'
          },
          colors: ['#EB3E41', '#229AFC'],
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,
              dataLabels: {
                enabled: true,
                format: '{point.name}'
              }
            }
          },
          series: []
        },
      }
    },
    components: {
      HChart,
      DateRange
    },
    methods: {
      async getData () {
        if (this.$store.state.curAccountId.length === 0) {
          return false;
        }
        let loadingInstance = Loading.service({target: '.cycle-profit-charts'});
        const params = {
          a_id: this.$store.state.curAccountId.join(','),
          type: this.$store.state.analysisType,
          b_date: this.$store.state.dateRange.b_date,
        e_date: this.$store.state.dateRange.e_date
        }
        let res = await apiAnalysisData('cycleProfit', params)
        if (res.data.code === 0 && res.data.data) {
          const resData = JSON.parse(JSON.stringify(res.data.data))
          this.highOptions.series = [{
            name: '周期盈亏',
            data: [['盈利',resData.f1], ['亏损',resData.f6]]
          }];
          this.daysOptions.series = [{
            name: '周期盈亏',
            data: [['盈利',resData.f2], ['亏损',resData.f7]]
          }];
          this.shortlineOptions.series = [{
            name: '周期盈亏',
            data: [['盈利',resData.f3], ['亏损',resData.f8]]
          }];
          this.midlineOptions.series = [{
            name: '周期盈亏',
            data: [['盈利',resData.f4], ['亏损',resData.f9]]
          }];
          this.klineOptions.series = [{
            name: '周期盈亏',
            data: [['盈利',resData.f5], ['亏损',resData.f10]]
          }];
          this.$refs.highChart.redraw();
          this.$refs.daysChart.redraw();
          this.$refs.shortlineChart.redraw();
          this.$refs.midlineChart.redraw();
          this.$refs.klineChart.redraw();
          loadingInstance.close();
        }
      }
    },
    mounted () {
      this.getData();
    }
  }
</script>

<style lang="scss">
    .cycle-profit {
        .el-card {
            .chart {
                height: 200px;
            }

            .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }
        }
    }
</style>
